import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { TemplateModule } from './template';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthConfig, AuthModule } from '@fhc/app/auth/auth.module';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import {
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth
} from '@angular/fire/auth';
import {
  connectAuthEmulatorInDevMode,
  connectFirestoreEmulatorInDevMode,
  connectFunctionsEmulatorInDevMode
} from './emulators';

const authConfig: AuthConfig = {
  enableFirestoreSync: false, // enable/disable autosync users with firestore
  toastMessageOnAuthSuccess: true, // whether to open/show a snackbar message on auth success - default : true
  toastMessageOnAuthError: true, // whether to open/show a snackbar message on auth error - default : true
  authGuardFallbackURL: '/', // url for unauthenticated users - to use in combination with canActivate feature on a route
  authGuardLoggedInURL: '/me/my-tags', // url for authenticated users - to use in combination with canActivate feature on a route
  passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
  passwordMinLength: 8, // Password length min/max in forms independently of each componenet min/max.
  // Same as password but for the name
  nameMaxLength: 50,
  nameMinLength: 2,
  // If set, sign-in/up form is not available until email has been verified.
  // Plus protected routes are still protected even though user is connected.
  guardProtectedRoutesUntilEmailIsVerified: true,
  enableEmailVerification: true // default: true
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,

    provideFirebaseApp(() => initializeApp(environment.firebase)),

    // BrowserTransferStateModule,
    // provideRemoteConfig(() => getRemoteConfig()),
    // provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver
      });
      connectAuthEmulatorInDevMode(auth);
      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'europe-west3');
      connectFunctionsEmulatorInDevMode(functions);
      return functions;
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),

    // AngularFireModule.initializeApp(environment.firebase),
    provideFirestore(() => {
      const firestore = getFirestore(getApp());
      connectFirestoreEmulatorInDevMode(firestore);
      return firestore;
    }),
    // provideMessaging(() => getMessaging()),
    // FunctionsModule,
    // provideFunctions(() => getFunctions()),
    // provideAuth(() => getAuth()),
    AuthModule.forRoot(authConfig), // environment.firebase, () => environment.firebase.appId,
    // AngularFireMessagingModule,
    // AngularFireFunctionsModule,
    TemplateModule,
    AppRoutingModule,
    MatSnackBarModule
  ],
  providers: [
    // { provide: FUNCTIONS_REGION, useValue: 'europe-west3' }
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: environment.emulator ? ['http://localhost:9099'] : undefined
    // },
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: environment.emulator ? ['localhost', 8080] : undefined
    // },
    // {
    //   provide: USE_FUNCTIONS_EMULATOR,
    //   useValue: environment.emulator ? ['localhost', 5001] : undefined
    // },
    // { provide: AUTH_PERSISTENCE, useValue: 'local' }
    // { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
    // // { provide: FUNCTIONS_ORIGIN, useFactory: () => isDevMode() ? undefined : location.origin }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
