import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from '@fhc/data/chat/chat.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { AuthService } from '@fhc/app/auth/services';

const nullPos: GeolocationPosition = {
  timestamp: 0,
  coords: { latitude: 0, longitude: 0, accuracy: 0, altitude: 0, altitudeAccuracy: 0, heading: 0, speed: 0 }
};

@Component({
  selector: 'app-ring',
  templateUrl: './ring.component.html',
  styleUrls: ['./ring.component.scss']
})
export class RingComponent implements OnInit, OnDestroy {

  public hasGeoPermission = false;
  public hasCameraPermission = false;
  public geoPosition: GeolocationPosition = nullPos;
  public isRinging = false;
  public hasUserName = false;
  public chat$ = this.chatService.chat$;
  public user$ = this.auth.user$.pipe(share());
  public userId = '';
  public tagId = '';
  private chatSub!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private auth: AuthService) {
    this.checkForGeoPermission();
    this.checkForCameraPermission();
  }

  ngOnInit() {
    // window.sessionStorage.removeItem('tag');
    this.tagId = this.route.snapshot.queryParamMap.get('tagId') || '';
    this.chatSub = this.chat$.subscribe((chat) => {
      if (chat) {
        this.userId = chat.initiator;
      }
    });
    this.chatService.tryResume(this.tagId);
  }

  // onUser(user) {
  //   if (this.tagId && user) {
  //     const isResuming = false; // this.callService.tryResume(this.tagId);
  //     if (!isResuming) {
  //       // warming up the call service ensures faster startup time
  //       this.chatService.startChat(this.tagId, this.geoPosition).subscribe();
  //     }
  //   }
  //   return user;
  // }

  ngOnDestroy() {
    if (this.chatSub) {
      this.chatSub.unsubscribe();
    }
  }
  public checkForGeoPermission() {
    navigator.permissions.query({ name: 'geolocation' })
      .then((result) => (this.hasGeoPermission = result.state === 'granted') && this.getGeoPosition());
  }

  public checkForCameraPermission() {
    // Not supported in FF
    navigator.permissions.query({ name: 'camera' } as any)
      .then((result) => (this.hasCameraPermission = result.state === 'granted'));
  }

  public getGeoPosition() {
    navigator.geolocation.getCurrentPosition((pos) => {
      console.log(pos);
      this.hasGeoPermission = true;
      this.geoPosition = pos;
    });
  }
  public startChat() {
    this.isRinging = true;
    this.chatService.startChat(this.tagId, this.geoPosition).subscribe();
  }

  // public ring() {
  //   if (!this.tagId) {
  //     console.log('no tag');
  //     return;
  //   }
  //   this.chatService.sendMsg('Hello..').toPromise();
  // }

  public login() {
    this.auth.showLoginDialog();
  }

  public setUserName(userName: string) {
    this.hasUserName = !!userName;
    this.auth.userName = userName;
    this.chatService.setUserName(userName);
  }

  public onChatMessage(msg: string) {
    if (msg) {
      this.chatService.sendMsg(msg);
    }
  }

  // public loginAnonymously(userName: string) {
  //   this.auth.signInWith(AuthProvider.ANONYMOUS, { email: '', password: '', displayName: userName }).then(() => {

  //   });
  // }

}
