<!-- <div>
  <div *ngFor="let msg of messages" class="msg-container" [class.msg-container-me]="msg.from.userId === userId">
    <div class="spacer"></div>
    <div class="profile-img">
    </div>
    <div class="msg mat-elevation-z4" >
      <span class="timestamp">{{msg.msgDate.toDate() | date:'shortTime'}}</span>
      <div class="msg-text">
        {{msg.text}}
      </div>
    </div>
  </div>
</div> -->
<div style="display: grid; grid-template-rows: auto auto 1fr auto; height: 100%;max-height: 100%">
  <div>
    <div class="user mat-elevation-z2" *ngFor="let usr of users">
      <img
        class="profile-img"
        [src]="usr?.img || 'assets/img/guest-user.jpg'"
      />
      {{ usr?.name || "Guest" }}
    </div>
  </div>

  <div><hr /></div>

  <chat-log
    *ngIf="chatObj"
    [messages]="messages"
    [userId]="userId"
  >
  </chat-log>
  <div style="display: grid; grid-template-columns: auto min-content">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Leave a message...</mat-label>
      <input
        #chatMessage
        matInput
        [(ngModel)]="chatMsg"
        (keyup.enter)="sendMessage(chatMessage.value)"
      />
    </mat-form-field>
    <button
      mat-fab
      [disabled]="!chatMessage.value"
      (click)="sendMessage(chatMessage.value)"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
