<!-- Toolbar -->
<div class="toolbar print-hidden" role="banner">
  <button mat-button [routerLink]="['']" style="width: fit-content; padding: 0">
    <img
      width="40"
      alt="Her.nu Logo"
      src="assets/her-nu-logo.png"
      style="width: 32px; height: 32px" />
  </button>
  <span>{{ title }}</span>
  <div class="spacer"></div>
  <!-- <a aria-label="Fronthouse on twitter" target="_blank" rel="noopener" href="https://twitter.com/fronthousecons"
    title="Twitter">
    <svg id="twitter-logo" height="24" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <rect width="400" height="400" fill="none" />
      <path
        d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
        fill="#fff" />
    </svg>
  </a> -->

  <div *ngIf="(user$ | async)?.uid" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
    <!-- <button routerLink="me/my-tags">My tags</button> -->
    <button mat-button class="button-label-below" routerLink="me/my-tags">
      <img src="assets/icons/tags.svg" style="width: 24px" />
      <div>My tags</div>
    </button>
    <button mat-button class="button-label-below" routerLink="me/devices">
      <mat-icon style="width: 24px">devices</mat-icon>
      <div>My devices</div>
    </button>
    <img
      class="profile"
      [src]="(user$ | async)?.photoURL || 'assets/img/guest-user.jpg'"
      (click)="logout()"
      title="Log out" />
  </div>
  <!-- <div>{{user$ | async | json}}</div> -->

  <button mat-button *ngIf="!(user$ | async)?.uid" (click)="login()">Sign up or Login</button>
</div>
{{ user$ | async | json }} {{ hasMsgPermission$ | async | json }}
<div
  class="notificationPermission"
  *ngIf="(user$ | async)?.uid && (hasMsgPermission$ | async) === false">
  <div>In order to send you notifications, we need your permission</div>
  <button mat-raised-button (click)="getNotificationPermission()">OK</button>
</div>
<div class="notifications">
  <div class="notification" *ngFor="let msg of messages$ | async">
    New message: {{ msg.notification.body }}
    <button mat-raised-button (click)="respondToMessage(msg)">Respond</button>
  </div>
</div>
<div *ngIf="isInstallPromptVisible" class="add-to-homescreen">
  Add to homescreen?
  <button (click)="onAppInstall()"></button>
</div>
<div
  class="main content"
  role="main"
  [@fadeAnimation]="mainoutlet.isActivated ? mainoutlet.activatedRoute : ''">
  <router-outlet class="mainoutlet" #mainoutlet="outlet"></router-outlet>
</div>
