import { Component, ViewChild, AfterViewInit, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import jsQR, { QRCode } from 'jsqr';

interface Point {
  x: number;
  y: number;
}

@Component({
  selector: 'app-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.scss']
})
export class QrScanComponent implements AfterViewInit, OnDestroy {
  @Output() public qrData = new EventEmitter<string>();
  public hasVideoAccess = true;
  @ViewChild('qrcanvas', { static: true })
  private qrCanvas!: ElementRef<HTMLCanvasElement>;
  private canvasContext!: CanvasRenderingContext2D | null;
  private video!: HTMLVideoElement;
  private qrValid = false;
  hasQrCodeFound = false;
  codedata = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  ngAfterViewInit() {
    this.initQrReader();
  }
  ngOnDestroy() {
    if (this.video) {
      this.video.pause();
      const tracks = ((this.video.srcObject) as any).getVideoTracks();
      tracks[0].stop();
      this.video.src = '';
      // tslint:disable:no-null-keyword
      this.video.srcObject = null;
    }
  }


  private initQrReader() {
    this.hasVideoAccess = true;
    // this.hasQrCodeFound = false;
    this.canvasContext = this.qrCanvas.nativeElement.getContext('2d');
    if (this.canvasContext && navigator.mediaDevices) {
      this.video = document.createElement('video');
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then(async (stream: MediaStream) => {
          this.video.srcObject = stream;
          // no full screen for iOS
          this.video.setAttribute('playsinline', 'true');
          await this.video.play();
          this.video.playbackRate = 0.5;
          requestAnimationFrame(this.drawVideoFrame.bind(this));
        }).catch(async () => {
          this.hasVideoAccess = false;
        });
    } else {
      this.hasVideoAccess = false;
    }
  }

  private drawLine(begin: Point, end: Point, color: string): void {
    if (this.canvasContext) {
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(begin.x, begin.y);
      this.canvasContext.lineTo(end.x, end.y);
      this.canvasContext.lineWidth = 3;
      this.canvasContext.strokeStyle = color;
      this.canvasContext.stroke();
    }
  }
  private drawVideoFrame(): void {
    if (!this.canvasContext) {
      // we can't draw video frame
      return;
    }
    const canvasElm = this.qrCanvas.nativeElement;
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      // draw video frame from camera and mark found QR code in frame

      canvasElm.height = this.video.videoHeight;
      canvasElm.width = this.video.videoWidth;
      this.canvasContext.drawImage(this.video, 0, 0, canvasElm.width, canvasElm.height);
      const imageData: ImageData = this.canvasContext.getImageData(0, 0,
        canvasElm.width, canvasElm.height);
      const code: QRCode | null = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        // draw rectangle around found code in video frame
        const color = '#409e26';
        this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, color);
        this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, color);
        this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, color);
        this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, color);
        // QR code found
        if (this.codedata !== code.data) {
          this.codedata = code.data;
          this.hasQrCodeFound = true;
          this.qrData.emit(code.data);
        }
      } else {
        // no QR code found
        this.hasQrCodeFound = false;
        this.codedata = '';
      }
    }
    requestAnimationFrame(this.drawVideoFrame.bind(this));
  }

}
