import { NgModule } from '@angular/core';
import { RingModule } from './ring';
import { FrontpageModule } from './frontpage';
import { RespondModule } from '@fhc/pages/respond';
import { ProductsModule } from './products';
import { ScanQRModule } from './scan-qr';

@NgModule({
    imports: [ScanQRModule, RingModule, FrontpageModule, RespondModule, ProductsModule]
})
export class PagesModule {}
