import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'her.nu';
  constructor(
    private router: Router) { }

  ngOnInit() {
    const tag = window.location.search.substr(1);
    const path = window.location.pathname;
    if (tag && (path === '/' || path === '/index.html')) {
      this.router.navigate(['ring'], {queryParams: {tagId: tag}});
    }
  }
}
