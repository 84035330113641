import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ScanQRComponent } from './scan-qr.component';
import { MatIconModule } from '@angular/material/icon';
import { QrScanModule } from '@fhc/features/qr-scan';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    QrScanModule
  ],
  declarations: [ScanQRComponent]
})
export class ScanQRModule { }
