// @angular/*
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
// @angular/fire
// import { FIREBASE_APP_NAME, FIREBASE_OPTIONS } from '@angular/fire';
// import { FireAuthModule } from '@angular/fire/auth';
import { FirestoreModule } from '@angular/fire/firestore';
// @angular/material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';

import { DomSanitizer } from '@angular/platform-browser';
// auth
// components
import { AuthComponent } from './components/auth/auth.component';
import { UserComponent } from './components/user/user.component';
import { AuthProvidersComponent } from './components/providers/auth.providers.component';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { AuthConfig, authConfigFactory } from './interfaces/config.interface';
import { AvatarComponent } from './components/avatar/avatar.component';
import { LegalityDialogComponent } from './components/legality-dialog/legality-dialog.component';
// services
import { AuthConfigToken, UserProvidedConfigToken } from './tokens';
import { AuthService } from './services';

// interfaces
// ###################################################################################################
// Export module's public API
// components
export { LegalityDialogComponent } from './components/legality-dialog/legality-dialog.component';
export { LinkMenuItem, AvatarComponent } from './components/avatar/avatar.component';
export { UserComponent } from './components/user/user.component';
export { AuthComponent } from './components/auth/auth.component';
export {
  AuthProvidersComponent,
  Layout,
  Theme
} from './components/providers/auth.providers.component';
export { LoginComponent } from './components/login/login.component';
export { RegisterComponent } from './components/register/register.component';

// guards
export { AuthReadyGuard } from './guards/auth-ready.guard';
export { LoggedInGuard } from './guards/logged-in.guard';
// interfaces
export { AuthConfig } from './interfaces/config.interface';
// services
export { AuthProvider } from './services/auth.provider';
export { AuthService } from './services';
// export { FirestoreSyncService } from './services/firestore-sync.service';

@NgModule({
  imports: [
    CommonModule,
    // HTTP
    RouterModule,
    // FLEX_LAYOUT
    FlexLayoutModule,
    // FORMS
    FormsModule,
    ReactiveFormsModule,
    // MATERIAL
    MatTabsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDividerModule,
    MatChipsModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatMenuModule,
    // ANGULAR MATERIAL EXTENSIONS
    MatPasswordStrengthModule,
    // ANGULARFIRE2
    // FireAuthModule,
    FirestoreModule
  ],
  exports: [
    AuthComponent,
    UserComponent,
    AvatarComponent,
    AuthProvidersComponent,
    EmailConfirmationComponent,
    // LoggedInGuard,
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    LoginComponent,
    RegisterComponent
  ],
  declarations: [
    AuthComponent,
    UserComponent,
    AvatarComponent,
    AuthProvidersComponent,
    EmailConfirmationComponent,
    LegalityDialogComponent,
    LoginComponent,
    RegisterComponent
  ]
})
export class AuthModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    auth: AuthService
  ) {
    auth.listenToUserEvents();
    this.registerProviderIcons();
  }

  static forRoot(
    // configFactory: FirebaseOptions,
    // appNameFactory: () => string | undefined = () => undefined,
    config: AuthConfig = {}
  ): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        // {
        //   provide: FIREBASE_OPTIONS,
        //   useValue: configFactory,
        // },
        // {
        //   provide: FIREBASE_APP_NAME,
        //   useFactory: appNameFactory,
        // },
        { provide: UserProvidedConfigToken, useValue: config },
        {
          provide: AuthConfigToken,
          useFactory: authConfigFactory,
          deps: [UserProvidedConfigToken]
        }
      ]
    };
  }

  registerProviderIcons() {
    this.iconRegistry
      .addSvgIcon(
        'google',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/google.svg')
      )
      .addSvgIcon(
        'apple',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/apple.svg')
      )
      .addSvgIcon(
        'google-colored',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/google.svg')
      )
      .addSvgIcon(
        'facebook',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/facebook.svg')
      )
      .addSvgIcon(
        'twitter',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/twitter.svg')
      )
      .addSvgIcon(
        'github',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/github-circle.svg')
      )
      .addSvgIcon(
        'microsoft',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/microsoft.svg')
      )
      .addSvgIcon(
        'yahoo',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/mdi/yahoo.svg')
      )
      .addSvgIcon('phone', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/auth/phone.svg'));
  }
}
