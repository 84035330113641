import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

const defaultConfig: MatSnackBarConfig = {
    duration: 0,
    horizontalPosition: 'center',
    verticalPosition: 'bottom'
};

@Injectable({ providedIn: 'root' })
export class AlertService {

    constructor(private snackBar: MatSnackBar) { }
    public info(msg: string) {
        const conf: MatSnackBarConfig = {
            ...defaultConfig,
            duration: 3000,
        };
        this.snackBar.open(msg, undefined, conf);
    }

    public error(msg: string) {
        this.snackBar.open(msg, undefined, defaultConfig);
    }
}
