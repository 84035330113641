import { Component } from '@angular/core';
import { AuthService } from '@fhc/app/auth/services';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss']
})
export class FrontpageComponent {

  constructor(public auth: AuthService) { }
  public signup() {
    this.auth.showLoginDialog();
  }

}
