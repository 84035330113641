import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthReadyGuard implements CanActivate {
    constructor(private auth: AuthService) { }
    canActivate(): Observable<boolean> {
        return this.auth.isReady$;
    }
}
