import { forwardRef, Inject, Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  TwitterAuthProvider,
  User,
  UserCredential
} from '@angular/fire/auth';
import { Subject } from 'rxjs';
// import { FirestoreSyncService } from './firestore-sync.service';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { ICredentials, AuthConfig } from '../interfaces';
import { AuthConfigToken } from '../tokens';
import { BaseAuthService } from './base-auth.service';
import { AuthProvider } from './auth.provider';
import { UserInfo } from '../interfaces/user.interface';

const facebookAuthProvider = new FacebookAuthProvider();
const googleAuthProvider = new GoogleAuthProvider();
const appleAuthProvider = new OAuthProvider('apple.com');
const twitterAuthProvider = new TwitterAuthProvider();
const githubAuthProvider = new GithubAuthProvider();
const microsoftAuthProvider = new OAuthProvider('microsoft.com');
const yahooAuthProvider = new OAuthProvider('yahoo.com');

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseAuthService {
  public showLoginDialog$ = new Subject<void>();

  constructor(
    public afa: Auth,
    @Inject(forwardRef(() => AuthConfigToken)) config: AuthConfig,
    snackBar: MatSnackBar,
    // fireStoreService: FirestoreSyncService,
    @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) matSnackBarConfig: MatSnackBarConfig
  ) {
    super(config, snackBar, matSnackBarConfig);
  }

  listenToUserEvents() {
    this.afa.onAuthStateChanged((user: User | null) => {
      this.setUser(user);
    });
  }

  /**
   * Fires a showLoginDialog event. Does nothing, it's up to code in the app to show the dialog.
   */
  public showLoginDialog() {
    this.showLoginDialog$.next();
  }
  /**
   * Reset the password of the auth-user via email
   *
   * @param email - the email to reset
   */
  public async resetPassword(email: string): Promise<void> {
    try {
      console.log('Password reset email sent');
      // TODO
      // return await this.afa.sendPasswordResetEmail(email);
    } catch (error) {
      return this.notifyError(error);
    }
  }

  /**
   * General sign in mechanism to authenticate the users with a firebase project
   * using a traditional way, via username and password or by using an authentication provider
   * like google, facebook, twitter and github
   *
   * @param provider - the provider to authenticate with (google, facebook, twitter, github)
   * @param credentials optional email and password
   */
  public async signInWithRedirect(provider: AuthProvider, credentials?: ICredentials) {
    try {
      let signInResult: UserCredential | any;

      switch (provider) {
        case AuthProvider.ANONYMOUS:
          signInResult = (await signInAnonymously(this.afa)) as UserCredential;
          break;
        case AuthProvider.EmailAndPassword:
          if (!credentials) {
            throw new Error('Missing credentials');
          }
          signInResult = (await signInWithEmailAndPassword(
            this.afa,
            credentials.email,
            credentials.password
          )) as UserCredential;
          break;
        case AuthProvider.Google:
          signInResult = await signInWithRedirect(this.afa, googleAuthProvider);
          break;
        case AuthProvider.Apple:
          signInResult = await signInWithRedirect(this.afa, appleAuthProvider);
          break;
        case AuthProvider.Facebook:
          signInResult = await signInWithRedirect(this.afa, facebookAuthProvider);
          break;
        case AuthProvider.Twitter:
          signInResult = await signInWithRedirect(this.afa, twitterAuthProvider);
          break;
        case AuthProvider.Github:
          signInResult = await signInWithRedirect(this.afa, githubAuthProvider);
          break;
        case AuthProvider.Microsoft:
          signInResult = await signInWithRedirect(this.afa, microsoftAuthProvider);
          break;
        case AuthProvider.Yahoo:
          signInResult = await signInWithRedirect(this.afa, yahooAuthProvider);
          break;
        case AuthProvider.PhoneNumber:
          // coming soon - see feature/sms branch
          break;
        default:
          throw new Error(`${provider} is not available as auth provider`);
      }
      // await this.handleSuccess(signInResult);
    } catch (err) {
      this.handleError(err);
    }
  }

  /**
   * General sign in mechanism to authenticate the users with a firebase project
   * using a traditional way, via username and password or by using an authentication provider
   * like google, facebook, twitter and github
   *
   * @param provider - the provider to authenticate with (google, facebook, twitter, github)
   * @param credentials optional email and password
   */
  public async signInWith(provider: AuthProvider, credentials?: ICredentials) {
    try {
      let signInResult: UserCredential | any;
      switch (provider) {
        case AuthProvider.ANONYMOUS:
          signInResult = (await signInAnonymously(this.afa)) as UserCredential;
          if (credentials && credentials.displayName) {
            signInResult.user.displayName = credentials.displayName;
            signInResult.user.photoURL = 'assets/img/guest-user.jpg';
            // await this.fireStoreService.updateUserData(
            //     {
            //         name: credentials.displayName,
            //         displayName: credentials.displayName,
            //         photoURL: credentials.photoURL || 'assets/img/guest-user.jpg'
            //     });
            // await (signInResult as UserCredential).user.updateProfile(
            //     { displayName: credentials.displayName, photoURL: credentials.photoURL || 'assets/img/guest-user.jpg'});
          }
          break;
        case AuthProvider.EmailAndPassword:
          if (!credentials) {
            throw new Error('Missing credentials');
          }
          signInResult = (await signInWithEmailAndPassword(
            this.afa,
            credentials.email,
            credentials.password
          )) as UserCredential;
          break;
        case AuthProvider.Google:
          signInResult = (await signInWithPopup(this.afa, googleAuthProvider)) as UserCredential;
          break;
        case AuthProvider.Apple:
          signInResult = (await signInWithPopup(this.afa, appleAuthProvider)) as UserCredential;
          break;
        case AuthProvider.Facebook:
          signInResult = (await signInWithPopup(this.afa, facebookAuthProvider)) as UserCredential;
          break;
        case AuthProvider.Twitter:
          signInResult = (await signInWithPopup(this.afa, twitterAuthProvider)) as UserCredential;
          break;
        case AuthProvider.Github:
          signInResult = (await signInWithPopup(this.afa, githubAuthProvider)) as UserCredential;
          break;
        case AuthProvider.Microsoft:
          signInResult = (await signInWithPopup(this.afa, microsoftAuthProvider)) as UserCredential;
          break;
        case AuthProvider.Yahoo:
          signInResult = (await signInWithPopup(this.afa, yahooAuthProvider)) as UserCredential;
          break;
        case AuthProvider.PhoneNumber:
          // coming soon - see feature/sms branch
          break;
        default:
          if (!provider) {
            throw new Error(`Unknown provider`);
          }
          throw new Error(`${provider} is not available as auth provider`);
      }
      await this.handleSuccess(signInResult);
    } catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Sign up new users via email and password.
   * After that the auth-user should verify and confirm an email sent via the firebase
   *
   * @param displayName - the displayName if the new auth-user
   * @param credentials email and password
   * @returns -
   */
  public async signUp(displayName: string, credentials: ICredentials) {
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(
        this.afa,
        credentials.email,
        credentials.password
      );
      const user = userCredential.user;
      if (!user) {
        throw new Error('No user');
      }
      await this.updateProfile({ displayName, photoURL: user.photoURL || '' });

      // if (this.config.enableFirestoreSync) {
      //     await this.fireStoreService
      //         .getUserDocRefByUID(user.uid)
      //         .set({
      //             uid: user.uid,
      //             displayName,
      //             email: user.email,
      //             photoURL: user.photoURL
      //         } as firebase.User);
      // }

      if (this.config.enableEmailVerification) {
        await sendEmailVerification(user);
      }

      // Legacy fields
      this.emailConfirmationSent = true;
      this.emailToConfirm = credentials.email;

      await this.handleSuccess(userCredential);
    } catch (err) {
      this.handleError(err);
    }
  }

  async signOut() {
    try {
      await this.afa.signOut();
    } catch (error) {
      this.notifyError(error);
    }
  }

  /**
   * Update the profile (name + photo url) of the authenticated auth-user in the
   * firebase authentication feature (not in firestore)
   *
   * @param name - the new name of the authenticated auth-user
   * @param photoURL - the new photo url of the authenticated auth-user
   * @returns -
   */
  public updateProfile(info: Partial<UserInfo>): Promise<void> {
    const user = this.afa.currentUser;
    if (!user) {
      return Promise.reject('No user');
    }
    //TODO: not implemented in new version
    return Promise.reject('Not implemented');
    // if (info.email || info.phoneNumber) {
    //   alert('email and phone number not yet supported');
    // }
    // if (!info.photoURL) {
    //   user..displayName = info.displayName;
    // } else {
    //   return user.updateProfile({ displayName: info.displayName, photoURL: info.photoURL });
    // }
    // return updateCurrentUser(this.afa, user);
  }

  public signInWithPhoneNumber() {
    // todo: 3.1.18
  }
}
