<p>
  Upgrade to Home or Business account plans to get extended functionality
</p>

<h2>Freemium</h2>
<p>The Freemium account is what you get when you sign up. It is free, personal and it will be yours forever</p>
<p></p>
<h2>Home</h2>
<p>The Home account is suited for you that own your own house.</p>
<h2>Business</h2>
<p>The Business account is suited for you that needs better user management control</p>
<p>A Business account also offers a more customisable landing page with more features</p>

<div class="four-col-grid grid-left-auto">
  <div></div><div>Freemium</div><div>Home</div><div>Business</div>
  <div>Doorbell tag for 1 user</div>    <div>✔</div><div>✔</div><div>✔</div>
  <div>Reach multiple users</div>       <div></div><div>✔</div><div>✔</div>
  <div>Chat</div>                       <div>✔</div><div>✔</div><div>✔</div>
  <div>Audio call</div>                 <div></div><div>✔</div><div>✔</div>
  <div>Video call</div>                 <div></div><div>✔</div><div>✔</div>
  <div>Customizable quick-replies</div> <div></div><div>✔</div><div>✔</div>
  <div>Included doorbell sign</div>     <div></div><div>✔</div><div>✔</div>
  <div>Ads</div>                        <div>✔</div><div></div><div></div>
  <div>Doorbell panel/select group to reach from a single tag</div><div></div><div></div><div>✔</div>
  <div>User & role management</div>     <div></div><div></div><div>✔</div>
  <div>Premium support</div>            <div></div><div></div><div>✔</div>
  <div>Design & order your own sign/sticker(s)</div><div></div><div></div><div></div>
  <div>Recorded video messages</div>    <div></div><div></div><div></div>
  <div>Theming</div>                    <div></div><div></div><div></div>
  <div>Transfer account ownership</div> <div></div><div></div><div></div>
  <div>SMS notifications</div>          <div></div><div></div><div></div>
  <div></div><div></div><div><button mat-raised-button color="primary">Add now</button><br/>30 day free trial*</div><div><button mat-raised-button color="primary">Add now</button><br/>30 day free trial*</div>
  
</div>
      

<p>* By adding a home or business account you need to sign up for a free 30 day trial using a credit card. If you delete the account before the 30 days have passed, you will not be billed. Otherwize, an annual subscription is made active.</p>
<!-- Doorbell tag for 1 user	✔		✔	✔
Chat	✔		✔	✔
Reach multiple users			✔	✔
Audio call			✔	✔
Video call			✔	✔
Customizable quick-replies			✔	✔
Included doorbell sign			1	1
Ads	✔			
Doorbell panel/select group to reach from a single tag				✔
User & role management				✔
Premium support				✔
Design & order your own sign/sticker(s)	€		€	€
Recorded video messages				
Theming	✔		✔	✔
Transfer account ownership				✔
SMS notifications				 -->