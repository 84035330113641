import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthConfig } from '../interfaces';
import { AuthConfigToken } from '../tokens';
import { AuthService } from '../services';
import { AlertService } from '../../app/alert.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(
    @Inject(AuthConfigToken)
    private config: AuthConfig,
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user$.pipe(
      map((user) => {
        if (user && user.uid) {
          if (
            this.config.guardProtectedRoutesUntilEmailIsVerified &&
            !user.emailVerified &&
            !user.isAnonymous
          ) {
            if (this.config.authGuardFallbackURL) {
              this.router.navigate([`${this.config.authGuardFallbackURL}`], {
                queryParams: { redirectUrl: state.url }
              });
            }
            return false;
          } else {
            return true;
          }
        } else {
          this.alertService.info('You are not logged in, redirecting to frontpage.');
          if (this.config.authGuardFallbackURL) {
            this.router.navigate([`/${this.config.authGuardFallbackURL}`], {
              queryParams: { redirectUrl: state.url }
            });
          }
          return false;
        }
      })
    );
  }
}
