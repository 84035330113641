<!--
  This is the page a user use to respond to a call
  It may in the future hold a chat/video chat or other means of communication
-->

<mat-card>
  <button mat-raised-button (click)="sendMsg('I\'ll be right there...')">I'll be right there...</button>

  <button mat-raised-button (click)="sendMsg('I\'m busy, please leave a message')">I'm busy...</button>
</mat-card>
<!-- {{call$ | async | json}} -->
<mat-card *ngIf="(chat$ | async) as chat">
  <chat [chat]="chat" [userId]="userId" (newMessage)="sendMsg($event)">

  </chat>
</mat-card>