<!--
  This is the landing page of a qr lookup which purpose is to contact the owner of a tag
  It may in the future hold a chat/video chat or other means of communication
-->
<p *ngIf="!hasGeoPermission">
  Permissions needed:
  <button mat-raised-button class="" (click)="getGeoPosition()">Position</button>
</p>

<ng-container *ngIf="tagId">
  <mat-card *ngIf="(user$ | async) === undefined && !hasUserName">
    <mat-card-title>Please provide your name</mat-card-title>
    <mat-card-subtitle>It will make it more likely for a good response</mat-card-subtitle>
    <div style="display: grid; grid-template-columns: 1fr 32px 1fr; column-gap: 16px">
      <div style="display: grid; grid-template-columns: auto 40px">
        <mat-form-field>
          <mat-label>Enter your name please</mat-label>
          <input #userName matInput autocomplete="name" name="name" />
        </mat-form-field>
        <button
          mat-mini-fab
          color="secondary"
          [disabled]="!userName.value"
          (click)="setUserName(userName.value)">
          <mat-icon>login</mat-icon>
        </button>
      </div>
      <div style="font-size: 3em; line-height: 100%">or</div>
      <button mat-raised-button color="primary" style="width: 100%" (click)="login()">
        log in
      </button>
    </div>
  </mat-card>

  <br />

  <button
    *ngIf="!(chat$ | async)"
    [disabled]="isRinging"
    mat-raised-button
    color="primary"
    class="largebutton callbutton"
    (click)="startChat()">
    <mat-icon>notifications_active</mat-icon>&nbsp;Ring now
  </button>

  <!-- <div *ngIf="isRinging">
    Contacting owner...
  </div>

  <div>
  {{chat$ | async | json}}
  </div>

  <div *ngIf="false">
    Owner has seen your message
  </div>
  <div *ngIf="false">
    Owner is on his/her way
  </div> -->
  <ng-container *ngIf="chat$ | async as chat">
    <mat-card style="margin: 16px 0">
      <mat-card-header>
        <mat-icon mat-card-avatar>info_outline</mat-icon>
        <mat-card-title>
          <ng-container *ngIf="chat.devices.length > 0">
            We have notified {{ chat.users.length }}
            {{ chat.users.length === 1 ? 'person' : 'people' }}, please hang on for response.
          </ng-container>
          <ng-container *ngIf="chat.devices.length === 0">
            The recipient(s) does not have an active device and we were unable to connect.
          </ng-container>
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <mat-card>
      <chat
        [chat]="chat"
        [userId]="userId"
        (newMessage)="onChatMessage($event)"
        style="max-height: 50vh">
      </chat>
    </mat-card>
  </ng-container>
</ng-container>
