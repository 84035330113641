import { Component } from '@angular/core';
import { AuthService } from '@fhc/app/auth/services';
import { Router } from '@angular/router';

const nullPos: GeolocationPosition = {
  timestamp: 0,
  coords: { latitude: 0, longitude: 0, accuracy: 0, altitude: 0, altitudeAccuracy: 0, heading: 0, speed: 0 }
};

@Component({
  selector: 'app-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.scss']
})
export class ScanQRComponent {

  public hasGeoPermission = false;
  public hasCameraPermission = false;
  public geoPosition: GeolocationPosition = nullPos;
  public isScanning = false;
  public user$ = this.auth.user$;

  constructor(
    private router: Router,
    private auth: AuthService) {
    this.checkForCameraPermission();
  }

  public onQrData(qrData: string) {
    if (qrData.startsWith('https://her.nu')) {
      this.isScanning = false;
      const tagId = qrData.split('?')[1];
      this.router.navigate(['ring'], { queryParams: { tagId} });
    }
  }
  public checkForCameraPermission() {
    // may not work in FF
    navigator.permissions.query({ name: 'camera' } as any)
      .then((result) => (this.hasCameraPermission = result.state === 'granted'));
  }

}
