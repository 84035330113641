<div>
  <div *ngFor="let msg of visibleMessages; trackBy: getId " class="msg-container" [class.msg-container-me]="msg.userId === userId">
    <div class="spacer"></div>
    <div class="profile-img-col">
      <div style="position:relative;height:100%">
        <img class="profile-img" [src]="msg.img || 'assets/img/guest-user.jpg'" style="bottom:0;position:absolute">
      </div>
    </div>
    <div class="msg mat-elevation-z4" >
      <span class="timestamp">{{msg.msgDate.toDate() | date:'shortTime'}} - {{msg.name || 'Guest'}}</span>
      <div class="msg-text">
        {{msg.text}}
      </div>
    </div>
  </div>
</div>