import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ChatWithMsg, ChatMessage } from '@fhc/data/chat/chat.service';
import { ChatUser, Message } from '@fhc/shared/interfaces';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
  public chatMsg = '';
  public chatObj!: ChatWithMsg;

  @Input()
  public set chat(chat: ChatWithMsg) {
    this.chatObj = chat;
    if (chat) {
      const thisUser: ChatUser = {
        userId: chat.userId || chat.initiator,
        name: chat.initiatorName,
        img: ''
      };
      const initiator: ChatUser = {
        userId: chat.initiator,
        name: chat.initiatorName,
        img: ''
      };
      this.messages = addUserToMessages(chat.messages, [...chat.users, initiator, thisUser]);
      this.users = chat.users;
    } else {
      this.messages = [];
      this.users = [];
    }
  }

  @Input()
  public userId!: string;

  @Output() newMessage = new EventEmitter<string>();

  public messages: ChatMessage[] = [];

  public users: ChatUser[] = [];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  public sendMessage(message: string) {
    this.newMessage.emit(message);
    this.chatMsg = '';
  }

}

function getChatUsers(chat: ChatWithMsg) {
  const index:any = {};
  chat.devices.forEach((device) => {
    const user = index[device.userId] || {
      userId: device.userId,
      displayName: 'User',
      photoURL: 'assets/img/guest-user.jpg',
      devices: []
    };
    user.devices.push(device);
    index[device.userId] = user;
  });
  return Object.values(index);
}

function addUserToMessages(messages: Message[], users: ChatUser[]): ChatMessage[] {

  return messages.map((m) => {
    const user = users.find((u) => u.userId === m.userId);
    return {
      userId: m.userId,
      name: user?.name,
      img: user?.img,
      msgDate: m.msgDate,
      text: m.text,
      data: m.data
    } as ChatMessage;
  });
}

