<div [@animateStagger]="{ value: '50' }" [ngSwitch]="theme">

  <!--default icon buttons-->
  <div *ngSwitchDefault
       [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
       [fxLayout]="layout"
       fxLayout.xs="column">
    <button (click)="processLegalSignUP(authProvider.Google)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Google)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            mat-button>
      <mat-icon svgIcon="google-colored"></mat-icon>
      Google
    </button>

    <button (click)="processLegalSignUP(authProvider.Apple)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Apple)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="apple-filled"
            mat-button>
      <mat-icon svgIcon="apple"></mat-icon>
      Apple
    </button>

    <button (click)="processLegalSignUP(authProvider.Facebook)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Facebook)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="facebook-filled"
            mat-button>
      <mat-icon svgIcon="facebook"></mat-icon>
      Facebook
    </button>
    <button (click)="processLegalSignUP(authProvider.Twitter)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Twitter)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="twitter-filled"
            mat-button>
      <mat-icon svgIcon="twitter"></mat-icon>
      Twitter
    </button>
    <button (click)="processLegalSignUP(authProvider.Github)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Github)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            mat-button>
      <mat-icon svgIcon="github"></mat-icon>
      GitHub
    </button>
    <button (click)="processLegalSignUP(authProvider.Microsoft)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Microsoft)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            mat-button>
      <mat-icon svgIcon="microsoft"></mat-icon>
      Microsoft
    </button>
    <button (click)="processLegalSignUP(authProvider.Yahoo)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Yahoo)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            mat-button>
      <mat-icon svgIcon="yahoo"></mat-icon>
      Yahoo
    </button>
  </div>

  <!--classic-->
  <div *ngSwitchCase="themes.CLASSIC"
       [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
       [fxLayout]="layout"
       class="buttons-classic"
       fxLayout.xs="column">
    <button (click)="processLegalSignUP(authProvider.Google)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Google)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="google-classic"
            mat-button>
      Google
    </button>
    <button (click)="processLegalSignUP(authProvider.Apple)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Apple)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="apple-classic"
            mat-button>
      Apple
    </button>
    <button (click)="processLegalSignUP(authProvider.Facebook)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Facebook)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="facebook-classic"
            mat-button>
      Facebook
    </button>
    <button (click)="processLegalSignUP(authProvider.Twitter)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Twitter)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="twitter-classic"
            mat-button>
      Twitter
    </button>
    <button (click)="processLegalSignUP(authProvider.Github)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Github)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="github-classic"
            mat-button>
      GitHub
    </button>
    <button (click)="processLegalSignUP(authProvider.Microsoft)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Microsoft)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="microsoft-classic"
            mat-button>
      Microsoft
    </button>
    <button (click)="processLegalSignUP(authProvider.Yahoo)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Yahoo)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="yahoo-classic"
            mat-button>
      Yahoo
    </button>
  </div>

  <!--stroked-->
  <div *ngSwitchCase="themes.STROKED"
       [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
       [fxLayout]="layout"
       class="buttons-classic"
       fxLayout.xs="column">
    <button (click)="processLegalSignUP(authProvider.Google)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Google)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="google-classic"
            mat-stroked-button>
      Google
    </button>
    <button (click)="processLegalSignUP(authProvider.Apple)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Apple)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="apple-classic"
            mat-stroked-button>
      Apple
    </button>
    <button (click)="processLegalSignUP(authProvider.Facebook)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Facebook)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="facebook-classic"
            mat-stroked-button>
      Facebook
    </button>
    <button (click)="processLegalSignUP(authProvider.Twitter)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Twitter)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="twitter-classic"
            mat-stroked-button>
      Twitter
    </button>
    <button (click)="processLegalSignUP(authProvider.Github)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Github)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="github-classic"
            mat-stroked-button>
      GitHub
    </button>
    <button (click)="processLegalSignUP(authProvider.Microsoft)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Microsoft)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="microsoft-classic"
            mat-stroked-button>
      Microsoft
    </button>
    <button (click)="processLegalSignUP(authProvider.Yahoo)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Yahoo)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="yahoo-classic"
            mat-stroked-button>
      Yahoo
    </button>
  </div>

  <!--raised-->
  <div *ngSwitchCase="themes.RAISED"
       [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
       [fxLayout]="layout"
       class="buttons-raised"
       fxLayout.xs="column">
    <button (click)="processLegalSignUP(authProvider.Google)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Google)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="google-raised"
            mat-raised-button>
      Google
    </button>
    <button (click)="processLegalSignUP(authProvider.Apple)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Apple)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="apple-raised"
            mat-raised-button>
      Apple
    </button>
    <button (click)="processLegalSignUP(authProvider.Facebook)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Facebook)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="facebook-raised"
            mat-raised-button>
      Facebook
    </button>
    <button (click)="processLegalSignUP(authProvider.Twitter)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Twitter)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="twitter-raised"
            mat-raised-button>
      Twitter
    </button>
    <button (click)="processLegalSignUP(authProvider.Github)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Github)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="github-raised"
            mat-raised-button>
      GitHub
    </button>
    <button (click)="processLegalSignUP(authProvider.Microsoft)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Microsoft)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="microsoft-raised"
            mat-raised-button>
      Microsoft
    </button>
    <button (click)="processLegalSignUP(authProvider.Yahoo)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Yahoo)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            [ngClass.xs]="{'space-full-xs':true}"
            class="yahoo-raised"
            mat-raised-button>
      Yahoo
    </button>
  </div>

  <!--fab-->
  <div *ngSwitchCase="themes.FAB"
       [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
       [fxLayout]="layout"
       class="buttons-raised">
    <button (click)="processLegalSignUP(authProvider.Google)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Google)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="google-raised"
            mat-fab>
      <mat-icon svgIcon="google"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Apple)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Apple)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="apple-raised"
            mat-fab>
      <mat-icon svgIcon="apple"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Facebook)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Facebook)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="facebook-raised"
            mat-fab>
      <mat-icon svgIcon="facebook"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Twitter)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Twitter)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="twitter-raised"
            mat-fab>
      <mat-icon svgIcon="twitter"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Github)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Github)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="github-raised"
            mat-fab>
      <mat-icon svgIcon="github"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Microsoft)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Microsoft)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="microsoft"
            mat-fab>
      <mat-icon svgIcon="microsoft"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Yahoo)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Yahoo)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="yahoo-raised"
            mat-fab>
      <mat-icon svgIcon="yahoo"></mat-icon>
    </button>
  </div>

  <!--mini-fab-->
  <div *ngSwitchCase="themes.MINI_FAB"
       [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
       [fxLayout]="layout"
       class="buttons-raised"
       fxLayoutAlign.xs="center center">
    <button (click)="processLegalSignUP(authProvider.Google)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Google)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="google-raised"
            fxFlexAlign="center"
            mat-mini-fab>
      <mat-icon svgIcon="google"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Apple)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Apple)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="apple-raised"
            mat-mini-fab>
      <mat-icon svgIcon="apple"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Facebook)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Facebook)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="facebook-raised"
            mat-mini-fab>
      <mat-icon svgIcon="facebook"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Twitter)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Twitter)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="twitter-raised"
            mat-mini-fab>
      <mat-icon class="icon-white" svgIcon="twitter"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Github)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Github)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="github-raised"
            mat-mini-fab>
      <mat-icon svgIcon="github"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Microsoft)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Microsoft)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="microsoft"
            mat-mini-fab>
      <mat-icon svgIcon="microsoft"></mat-icon>
    </button>
    <button (click)="processLegalSignUP(authProvider.Yahoo)"
            *ngIf="providers === authProvider.ALL || providers.includes(authProvider.Yahoo)"
            [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
            class="yahoo-raised"
            mat-mini-fab>
      <mat-icon svgIcon="yahoo"></mat-icon>
    </button>
  </div>
</div>
