import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { RingComponent } from './pages/ring/ring.component';
import { PagesModule } from './pages';
import { RespondComponent } from '@fhc/pages/respond';
import { ProductsComponent } from './pages/products/products.component';
import { ScanQRComponent } from './pages/scan-qr/scan-qr.component';
import { LoggedInGuard, AuthReadyGuard } from '@fhc/app/auth/auth.module';

@Injectable({
  providedIn: 'root'
})
export class TagGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const hasTagId = route.queryParamMap.has('tagId');
    if (!hasTagId) {
      this.router.navigate(['scan']);
    }
    return hasTagId;
  }
}



const routes: Routes = [
  { path: '', component: FrontpageComponent, pathMatch: 'full', data: { title: 'Her.nu' } },
  { path: 'scan', component: ScanQRComponent, data: { title: 'Scan...' } },
  { path: 'ring', component: RingComponent, data: { title: 'Ring...' }, canActivate: [TagGuard, AuthReadyGuard] },
  { path: 'respond', component: RespondComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'me', canActivate: [LoggedInGuard], loadChildren: () => import('./me-pages/index').then(m => m.MeModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
