import { Component, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NotificationService } from '@fhc/app/notification';
import { take, tap } from 'rxjs/operators';
import { AuthService } from '@fhc/app/auth/services';
import { Router, NavigationEnd, RouterOutlet, Event as REvent } from '@angular/router';
import { AlertService } from '@fhc/app/app/alert.service';
import { AuthComponent, AuthProvider } from '@fhc/app/auth/auth.module';
import { MatDialog } from '@angular/material/dialog';
import { fadeAnimation } from '@fhc/app/app/animations';
import { environment } from '../../environments/environment';

interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  animations: [fadeAnimation]
})
export class TemplateComponent {
  @ViewChild('mainoutlet', { static: true }) outlet!: RouterOutlet;
  public user$ = this.auth.user$;
  public title = '';
  public hasMsgPermission$ = this.msgService.hasPermission$;
  public messages$ = this.msgService.messages$.pipe(
    tap((msgs) => {
      console.log(msgs);
      setTimeout(() => this.cdr.detectChanges());
    })
  );
  public isInstallPromptVisible = false;
  private userInstallEvent: BeforeInstallPromptEvent | null = null;

  @HostListener('window.beforeinstallprompt')
  public onBeforeInstallPrompt(e: BeforeInstallPromptEvent) {
    e.preventDefault();
    this.userInstallEvent = e;
    this.isInstallPromptVisible = true; // we may want to delay this
  }
  constructor(
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private msgService: NotificationService,
    private alertService: AlertService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.router.events.subscribe((e) => this.onRouterEvent(e));
    this.auth.showLoginDialog$.subscribe(() => this.login());
  }
  public login() {
    const dialogRef = this.dialog.open(AuthComponent);
    const component: AuthComponent = dialogRef.componentInstance;
    component.providers = [AuthProvider.Google];

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
    this.auth.user$.pipe(take(2)).subscribe((user) => user.uid && dialogRef.close());
    // this.auth.signInWithRedirect(AuthProvider.Google); // .then(() => this.onLoggedIn());
  }

  public logout() {
    this.router.navigate(['']);
    this.auth.signOut();
  }

  private onLoggedIn() {
    console.log('logged in');
  }

  private onFailedShowLogin(err: Error) {
    console.log(err);
  }

  public getNotificationPermission() {
    this.msgService.requestPermission(environment.vapidKey).catch((err) => {
      // todo: notify about error, could be missing api support
      this.alertService.info(err.message);
    });
  }

  public onAppInstall() {
    this.userInstallEvent?.prompt();
  }

  public respondToMessage(msg: any) {
    const tagId = msg.notification.data.tagId;
    const chatId = msg.notification.data.chatId; // link.split('call=')[1] || '';
    this.router.navigate(['/respond'], { queryParams: { tagId, chatId } });
  }

  private onRouterEvent(e: REvent) {
    // console.log(e);
    if (e instanceof NavigationEnd) {
      this.onNavigationEnd(e);
    }
  }

  private onNavigationEnd(e: NavigationEnd) {
    if (this.outlet.isActivated) {
      this.title = this.outlet.activatedRoute.snapshot.data.title;
    }
  }
}
