export const environment = {
  production: true,
  emulator: false,
  firebase: {
    apiKey: 'AIzaSyBO5Eao7UZcUuBWwCVHiJ-UP4LihD0bCMs',
    authDomain: 'her.nu',
    databaseURL: 'https://here-nu.firebaseio.com',
    projectId: 'here-nu',
    storageBucket: 'here-nu.appspot.com',
    messagingSenderId: '5863140388',
    appId: '1:5863140388:web:cf02950c6ba029f6d23cb1',
    measurementId: 'G-NB24GS01GN'
  },
  functionsRoot: 'https://europe-west3-here-nu.cloudfunctions.net',
  vapidKey:
    'BEtY2dwYOh2hiHQEEZu3x5jUSErXXFhNfMmtwCDqX6OOM-WBadKPWIqvpf17eOYiVhqhvgL-XDP6epLLQL37g04'
};
