<!--
  This is the landing page of a qr lookup which purpose is to contact the owner of a tag
  It may in the future hold a chat/video chat or other means of communication
-->

<mat-card>
  <mat-card-header>
    <div mat-card-avatar><mat-icon style="font-size:3em">info_outlined</mat-icon></div>
    <mat-card-title>How to reach someone</mat-card-title>
    <mat-card-subtitle>A tag, or QR code, is needed to reach someone</mat-card-subtitle>
  </mat-card-header>
  <h2>Option 1</h2>
  <p>Use your phones camera app and point it at the QR-code on the sticker/tag.</p>
  <p>Your phone should automatically show an option to open a web page. Click ok.</p>
  <h2>Option 2</h2>
  <p>Use QR scanner below</p>
  <!-- <p>If this does not happen, you may need to download a separate QR-code app from App Store or Google Play store</p>
  <p>You can also click the button below to use our built-in QR-scanner</p> -->
  <button *ngIf="!isScanning" mat-raised-button color="primary" (click)="isScanning=true" style="width:100%"><mat-icon>qr_code_scanner</mat-icon> Scan now</button>
  <app-qr-scan *ngIf="isScanning" style="width: 100%" (qrData)="onQrData($event)"></app-qr-scan>
  
</mat-card>
