<ng-container *ngIf="authProcess.user$ | async as user">
  <ng-container *ngIf="user.uid; else showForm">
    <!-- This component will be shown when:
    - we just sent a verification mail (notably after sign up)
    - we arrived from the guard after trying to access a protected route even though we are connected
  -->
    <div
      *ngIf="
        (config.guardProtectedRoutesUntilEmailIsVerified &&
          !user.emailVerified) ||
          (authProcess.emailConfirmationSent && !user.emailVerified);
        else signedInUser
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <auth-email-confirmation
        (signOut)="signOut()"
        [email]="user.email || ''"
        [goBackURL]="goBackURL"
        [messageOnEmailConfirmationSuccess]="messageOnEmailConfirmationSuccess"
        [sendNewVerificationEmailText]="sendNewVerificationEmailText"
        [signOutText]="signOutText"
        [template]="verifyEmailTemplate"
        [verifyEmailConfirmationText]="verifyEmailConfirmationText"
        [verifyEmailGoBackText]="verifyEmailGoBackText"
        [verifyEmailTitleText]="verifyEmailTitleText"
      >
      </auth-email-confirmation>
    </div>

    <ng-template #signedInUser>
      <div
        class="signed-in-container"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <img
          *ngIf="user?.photoURL; else noPhoto"
          [src]="user?.photoURL"
          class="account-circle"
        />
        <ng-template #noPhoto>
          <mat-icon class="account-circle">account_circle</mat-icon>
        </ng-template>
        <div class="user-display-name mat-title">{{ user?.displayName }}</div>
        <div class="user-email mat-body-2">{{ user?.email }}</div>
        <div class="actions">
          <mat-progress-bar
            *ngIf="isLoading"
            mode="indeterminate"
          ></mat-progress-bar>
          <a
            *ngIf="verifyEmailGoBackText"
            [routerLink]="goBackURL"
            class="go-back-button action-button"
            color="primary"
            mat-stroked-button
            >{{ verifyEmailGoBackText }}</a
          >
          <button
            (click)="signOut()"
            class="sign-out-button action-button"
            color="warn"
            mat-stroked-button
          >
            {{ signOutText }}
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #showForm>
  <div *ngIf="tabIndex !== 2">
    <mat-card-title>Sign in using your existing account with:</mat-card-title>
    <auth-providers
      [providers]="providers"
      [theme]="providersTheme"
      [tosUrl]="tosUrl"
      [privacyPolicyUrl]="privacyPolicyUrl"
    >
    </auth-providers>
    <br />
    <h2>
      <mat-icon style="vertical-align: middle">warning_outline</mat-icon>
      Preview notice
    </h2>
    <p>
      If you choose to create an account, you must understand that the service
      may change or even be deleted at any time. Your user data may become
      corrupted or even deleted without any warning.
    </p>
  </div>

  <ng-container *ngIf="providers.includes(authProvider.EmailAndPassword)">
    <p style="text-align: center">Or using email</p>
    <mat-divider></mat-divider>

    <mat-tab-group
      (selectedTabChange)="onTabChange($event)"
      [color]="color"
      [selectedIndex]="tabIndex"
    >
      <!--Sign in tab-->
      <mat-tab [label]="signInTabText">
        <mat-card>
          <mat-card-title>{{ signInCardTitleText }}</mat-card-title>
          <mat-card-content>
            <form
              (ngSubmit)="signIn()"
              [@animateStagger]="{ value: '50' }"
              [formGroup]="signInFormGroup"
            >
              <div fxLayout="column" fxLayoutAlign="center">
                <mat-form-field
                  [@animate]="{
                    value: '*',
                    params: { duration: '300ms', y: '100px' }
                  }"
                  [appearance]="appearance"
                >
                  <mat-label>{{ emailText }}</mat-label>
                  <input formControlName="email" matInput required />
                  <mat-icon [color]="color" matSuffix>email</mat-icon>
                  <mat-error
                    *ngIf="signInEmailFormControl.hasError('required')"
                  >
                    {{ emailErrorRequiredText }}
                  </mat-error>
                  <mat-error *ngIf="signInEmailFormControl.hasError('pattern')">
                    {{ emailErrorPatternText }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field
                  [@animate]="{
                    value: '*',
                    params: { duration: '300ms', y: '100px' }
                  }"
                  [appearance]="appearance"
                >
                  <mat-label>{{ passwordText }}</mat-label>
                  <input
                    [maxlength]="max"
                    [minlength]="min"
                    [type]="togglePass.type"
                    formControlName="password"
                    matInput
                    required
                  />
                  <mat-pass-toggle-visibility
                    #togglePass
                    matSuffix
                  ></mat-pass-toggle-visibility>
                  <mat-icon [color]="color" matSuffix>lock</mat-icon>
                  <mat-hint align="end" aria-live="polite">
                    {{ signInFormGroup.value.password.length }} / {{ max }}
                  </mat-hint>
                  <mat-error
                    *ngIf="sigInPasswordFormControl.hasError('required')"
                  >
                    {{ passwordErrorRequiredText }}
                  </mat-error>
                  <mat-error
                    *ngIf="sigInPasswordFormControl.hasError('minlength')"
                  >
                    {{ passwordErrorMinLengthText }}
                  </mat-error>
                  <mat-error
                    *ngIf="sigInPasswordFormControl.hasError('maxlength')"
                  >
                    {{ passwordErrorMaxLengthText }}
                  </mat-error>
                </mat-form-field>

                <button
                  [@animate]="{ value: '*', params: { x: '50px' } }"
                  [color]="color"
                  [disabled]="signInFormGroup.invalid"
                  class="space-top"
                  mat-raised-button
                  style="margin-top: 20px"
                  type="submit"
                >
                  {{ loginButtonText }}
                </button>
              </div>
            </form>

            <div fxLayoutAlign="center">
              <button
                (click)="createForgotPasswordTab()"
                *ngIf="resetPasswordEnabled"
                [@animate]="{ value: '*', params: { x: '-50px' } }"
                [color]="color"
                class="space-top"
                mat-button
              >
                {{ forgotPasswordButtonText }}
              </button>
            </div>
          </mat-card-content>
          <mat-card-footer *ngIf="isLoading">
            <mat-progress-bar
              [@animate]="{
                value: '*',
                params: { z: '50px', delay: '50ms', scale: '0.2' }
              }"
              mode="indeterminate"
            ></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
      </mat-tab>

      <!--tab register-->
      <mat-tab *ngIf="registrationEnabled" [label]="registerTabText">
        <mat-card>
          <mat-card-title>{{ registerCardTitleText }}</mat-card-title>
          <mat-card-content fxLayout="column" fxLayoutAlign="center">
            <form
              (ngSubmit)="
                signUpFormGroup.valid &&
                  processLegalSignUP(authProvider.EmailAndPassword)
              "
              [@animateStagger]="{ value: '50' }"
              [formGroup]="signUpFormGroup"
            >
              <div fxLayout="column" fxLayoutAlign="center">
                <!--name-->
                <mat-form-field
                  [@animate]="{ value: '*', params: { x: '50px' } }"
                  [appearance]="appearance"
                >
                  <!--labels will work only with @angular/material@6.2.0 -->
                  <mat-label>{{ nameText }}</mat-label>
                  <input
                    [formControl]="sigUpNameFormControl"
                    [maxlength]="config.nameMaxLength || 50"
                    [minlength]="config.nameMinLength || 2"
                    matInput
                    required
                  />
                  <mat-icon [color]="color" matSuffix>person</mat-icon>
                  <mat-hint align="end" aria-live="polite">
                    {{ signUpFormGroup.value.name?.length }} /
                    {{ config.nameMaxLength }}
                  </mat-hint>
                  <mat-error *ngIf="sigUpNameFormControl.hasError('required')">
                    {{ nameErrorRequiredText }}
                  </mat-error>
                  <mat-error *ngIf="sigUpNameFormControl.hasError('minlength')">
                    {{ nameErrorMinLengthText }}
                  </mat-error>
                  <mat-error *ngIf="sigUpNameFormControl.hasError('maxlength')">
                    {{ nameErrorMaxLengthText }}
                  </mat-error>
                </mat-form-field>

                <!--email-->
                <mat-form-field
                  [@animate]="{ value: '*', params: { x: '50px' } }"
                  [appearance]="appearance"
                >
                  <mat-label>{{ emailText }}</mat-label>
                  <input
                    [formControl]="sigUpEmailFormControl"
                    matInput
                    required
                    type="email"
                  />
                  <mat-icon [color]="color" matSuffix>email</mat-icon>
                  <mat-error *ngIf="sigUpEmailFormControl.hasError('required')">
                    {{ emailErrorRequiredText }}
                  </mat-error>
                  <mat-error *ngIf="sigUpEmailFormControl.hasError('pattern')">
                    {{ emailErrorPatternText }}
                  </mat-error>
                </mat-form-field>

                <!--password-->
                <div fxLayout="column">
                  <mat-form-field
                    [@animate]="{ value: '*', params: { x: '50px' } }"
                    [appearance]="appearance"
                  >
                    <mat-label>{{ passwordText }}</mat-label>
                    <input
                      [formControl]="sigUpPasswordFormControl"
                      [maxlength]="max"
                      [minlength]="min"
                      [type]="toggle.type"
                      matInput
                      name="password"
                      required
                    />
                    <mat-pass-toggle-visibility
                      #toggle
                      matSuffix
                    ></mat-pass-toggle-visibility>

                    <mat-icon [color]="color" matSuffix>lock</mat-icon>

                    <mat-hint align="end" aria-live="polite">
                      {{ signUpFormGroup.value.password?.length }} / {{ max }}
                    </mat-hint>

                    <mat-error
                      *ngIf="sigUpPasswordFormControl.hasError('required')"
                      class="cut-text"
                    >
                      {{ passwordErrorRequiredText }}
                    </mat-error>

                    <mat-error
                      *ngIf="sigUpPasswordFormControl.hasError('minlength')"
                      class="cut-text"
                    >
                      {{ passwordErrorMinLengthText }}
                    </mat-error>
                    <mat-error
                      *ngIf="sigUpPasswordFormControl.hasError('maxlength')"
                      class="cut-text"
                    >
                      {{ passwordErrorMaxLengthText }}
                    </mat-error>
                  </mat-form-field>

                  <mat-password-strength
                    #passwordStrength
                    [customValidator]="customValidator"
                    [enableDigitRule]="enableDigitRule"
                    [enableLengthRule]="enableLengthRule"
                    [enableLowerCaseLetterRule]="enableLowerCaseLetterRule"
                    [enableSpecialCharRule]="enableSpecialCharRule"
                    [enableUpperCaseLetterRule]="enableUpperCaseLetterRule"
                    [externalError]="sigUpPasswordFormControl.dirty"
                    [max]="max"
                    [min]="min"
                    [password]="signUpFormGroup.value.password"
                  >
                  </mat-password-strength>
                </div>

                <button
                  [@animate]="{ value: '*', params: { x: '100px' } }"
                  [color]="color"
                  [disabled]="signUpFormGroup.invalid"
                  mat-raised-button
                  style="margin-top: 20px"
                  type="submit"
                >
                  {{ registerButtonText }}
                </button>
              </div>
            </form>

            <button
              (click)="processLegalSignUP(authProvider.ANONYMOUS)"
              *ngIf="guestEnabled"
              [@animate]="{ value: '*', params: { x: '-100px' } }"
              [color]="color"
              mat-button
              style="margin-top: 20px"
            >
              <mat-icon>fingerprint</mat-icon>
              {{ guestButtonText }}
            </button>
          </mat-card-content>

          <mat-card-footer *ngIf="isLoading">
            <mat-progress-bar
              [@animate]="{
                value: '*',
                params: { z: '50px', delay: '50ms', scale: '0.2' }
              }"
              mode="indeterminate"
            ></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
      </mat-tab>

      <!--Reset password tab-->
      <mat-tab *ngIf="passwordResetWished" class="reset-password-tab">
        <ng-template mat-tab-label>
          <button
            (click)="passwordResetWished = false"
            class="reset-password-tab__close-button"
            mat-icon-button
          >
            {{ resetPasswordTabText }}
            <mat-icon>close</mat-icon>
          </button>
        </ng-template>
        <form
          (ngSubmit)="resetPasswordFormGroup.valid && resetPassword()"
          [@animateStagger]="{ value: '50' }"
          [formGroup]="resetPasswordFormGroup"
        >
          <mat-card class="reset-password-card">
            <mat-card-content>
              <mat-form-field
                [@animate]="{
                  value: '*',
                  params: { duration: '300ms', y: '100px' }
                }"
                [appearance]="appearance"
                class="full-width"
              >
                <mat-label> {{ resetPasswordInputText }} </mat-label>
                <input
                  [title]="resetPasswordInputText"
                  formControlName="email"
                  matInput
                  required
                />
                <mat-icon [color]="color" matSuffix>email</mat-icon>
                <mat-error
                  *ngIf="resetPasswordEmailFormControl.hasError('required')"
                >
                  {{ resetPasswordErrorRequiredText }}
                </mat-error>
                <mat-error
                  *ngIf="resetPasswordEmailFormControl.hasError('pattern')"
                >
                  {{ resetPasswordErrorPatternText }}
                </mat-error>
              </mat-form-field>
              <p *ngIf="passReset">{{ resetPasswordInstructionsText }}</p>
            </mat-card-content>
            <mat-card-actions fxLayoutAlign="center">
              <mat-progress-bar
                *ngIf="isLoading"
                mode="indeterminate"
              ></mat-progress-bar>
              <button
                [@animate]="{ value: '*', params: { x: '50px' } }"
                [color]="color"
                mat-raised-button
                type="submit"
              >
                {{ resetPasswordActionButtonText }}
              </button>
            </mat-card-actions>
          </mat-card>
        </form>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-template>
