<!--
Page for a random user who opens the main url without any referral
The user may want to
* ring someone (he/she has a code)
* Get information on what this is
* Create an account and get started
-->

<!-- Highlight Card -->
<!-- <div class="card card-small" (click)="login()">
    Login
  </div>

<button (click)="requestMessagingPermission()">
  Send meg meldinger...
</button> -->
<div class="two-col-grid space-bottom">
  <button mat-raised-button class="largebtn" routerLink="/ring"><img src="assets/img/home.png" style="width:90%;"><div>I am at someones door</div><div class="subtitle">The smart doorbell without costly hardware</div></button>
  <button mat-raised-button class="largebtn" routerLink="/ring"><img src="assets/img/bag.png" style="width:90%;"><div>I found a lost item</div><div class="subtitle">The luggage tag without personal details</div></button>
  <!-- <button mat-raised-button class="largebtn">What is her.nu?</button>
  <button mat-raised-button class="largebtn">How can I get started?</button> -->
</div>


<!-- <button routerLink="ring">Ring</button>
<button routerLink="me/my-tags">My tags</button> -->


<h2>What is her.nu</h2>
<p>Her.nu is a service for reaching people without having their regular contact details</p>
<p>A &quot;TAG&quot; (often a sticker with a QR code) is placed on a door, mailbox, bike, backpack or other item.</p>
<p>The sticker does not contain any information of who it belongs to, but by using a smartphone it enables direct contact with its owner(s).</p>

<h2>How can I get started?</h2>
<p *ngIf="!((auth.user$ | async)?.uid)"><button mat-raised-button color="primary" (click)="signup()">Create an account</button> and log in to start creating your own tags</p>
<p *ngIf="(auth.user$ | async)?.uid"><a mat-raised-button color="primary" [routerLink]="['me/my-tags']">Go to my tags</a> to start creating your own tags</p>

<h2>Her.nu is not production ready and currently invite only</h2>
<p>If you choose to create an account, you must understand that the service may change or even be deleted at any time. Your user data may become corrupted or even deleted without any warning.</p>
<p>Furthermore, instant stable delivery of notifications is under testing and subject of improvement.</p>

<!-- Footer -->
<footer>

</footer>

<!-- <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677"
  viewBox="0 0 2611.084 485.677">
  <path id="Path_39" data-name="Path 39"
    d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
    transform="translate(142.69 -634.312)" fill="#eee" />
</svg> -->