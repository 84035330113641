<ng-container *ngTemplateOutlet="verifyEmailTemplate; context: verifyEmailContext"></ng-container>
<ng-template #defaultVerifyEmail let-email="email" let-goBackURL="goBackURL"
             let-sendNewVerificationEmailText="sendNewVerificationEmailText"
             let-signOutText="signOutText"
             let-verifyEmailConfirmationText="verifyEmailConfirmationText" let-verifyEmailGoBackText="verifyEmailGoBackText"
             let-verifyEmailTitleText="verifyEmailTitleText">
  <mat-card class="verify-email">
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
      <mat-icon>email</mat-icon>
      <p class="title" fxLayout="column" fxLayoutAlign="center center">
        <span class="mat-subheading-2">{{ verifyEmailTitleText }}</span>
        <span class="mat-body-2">{{ email }}</span>
      </p>
      <p class="subtitle">{{ verifyEmailConfirmationText }}</p>
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
    <mat-card-actions fxLayout="column" fxLayoutAlign="center center">
      <button (click)="continue()" *ngIf="goBackURL" class="go-back-button action-button" mat-stroked-button>
        {{ verifyEmailGoBackText }}
      </button>
      <button (click)="sendNewVerificationEmail()" class="send-new-mail-button action-button"
              mat-stroked-button>{{ sendNewVerificationEmailText }}</button>
      <button (click)="this.signOut.emit()" class="sign-out-button action-button" color="warn"
              mat-stroked-button>{{ signOutText }}</button>
    </mat-card-actions>
  </mat-card>
</ng-template>
