import { EventEmitter } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Accounts } from '../enums';
import { AuthProvider } from './auth.provider';
// import { FirestoreSyncService } from './firestore-sync.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ICredentials, ISignInProcess, ISignUpProcess, AuthConfig } from '../interfaces';
import { UserInfo } from '../interfaces/user.interface';
import { sendEmailVerification, User, UserCredential } from '@angular/fire/auth';

const blankUser: UserInfo = {
  displayName: '',
  email: '',
  phoneNumber: '',
  photoURL: '',
  providerId: '',
  uid: '',
  isAnonymous: true,
  emailVerified: false
};

export abstract class BaseAuthService implements ISignInProcess, ISignUpProcess {
  onSuccessEmitter: EventEmitter<any> = new EventEmitter<any>();
  onErrorEmitter: EventEmitter<any> = new EventEmitter<any>();

  // Useful to know about auth state even between reloads.
  // Replace emailConfirmationSent and emailToConfirm.
  // tslint:disable-next-line: variable-name
  protected usr: User | null = null;
  private anonUserName = getAnonymousUserName();
  protected _user$ = new ReplaySubject<UserInfo>(1);
  public isLoggedIn$: Observable<boolean> = this._user$.pipe(map((user) => !!(user && user.uid)));

  public isReady$: Observable<boolean> = this._user$.pipe(map(() => true));
  public anonymousUId = getAnonymousUId();
  messageOnAuthSuccess = '';
  messageOnAuthError = '';

  // Legacy field that is set to true after sign up.
  // Value is lost in case of reload. The idea here is to know if we just sent a verification email.
  emailConfirmationSent = false;
  // Legacy filed that contain the mail to confirm. Same lifecycle than emailConfirmationSent.
  emailToConfirm = '';

  get user$(): Observable<UserInfo> {
    return this._user$.asObservable();
  }
  public get user(): UserInfo {
    return this.usr ? this.parseUserInfo(this.usr) : { ...blankUser };
  }
  protected setUser(user: User | null) {
    this.usr = user;
    this._user$.next(user ? this.parseUserInfo(user) : { ...blankUser });
  }

  public set userName(name: string) {
    if (sessionStorage) {
      this.anonUserName = name;
      sessionStorage.setItem('anonUserName', name);
    }
  }

  public get userName() {
    if (this.user && this.user.displayName) {
      return this.user.displayName;
    }
    return this.anonUserName;
  }

  constructor(
    public config: AuthConfig,
    protected snackBar: MatSnackBar,
    // protected fireStoreService: FirestoreSyncService,
    protected matSnackBarConfig: MatSnackBarConfig
  ) {
    console.log('base auth');
  }

  // listenToUserEvents() {
  //   this.afa.user.subscribe((user: firebase.User | null) => {
  //     this._user$.next(user);
  //   });
  // }

  /**
   * Reset the password of the auth-user via email
   *
   * @param email - the email to reset
   */
  abstract resetPassword(email: string): Promise<void>;

  /**
   * General sign in mechanism to authenticate the users with a firebase project
   * using a traditional way, via username and password or by using an authentication provider
   * like google, facebook, twitter and github
   *
   * @param provider - the provider to authenticate with (google, facebook, twitter, github)
   * @param credentials optional email and password
   */
  abstract signInWith(provider: AuthProvider, credentials?: ICredentials): void;

  /**
   * Sign up new users via email and password.
   * After that the auth-user should verify and confirm an email sent via the firebase
   *
   * @param displayName - the displayName if the new auth-user
   * @param credentials email and password
   * @returns -
   */
  abstract signUp(displayName: string, credentials: ICredentials): void;

  async sendNewVerificationEmail(): Promise<void | never> {
    if (!this.usr) {
      return Promise.reject(new Error('No signed in user'));
    }
    return sendEmailVerification(this.usr);
  }

  abstract signOut(): void;

  /**
   * Update the profile (name + photo url) of the authenticated auth-user in the
   * firebase authentication feature (not in firestore)
   *
   * @param name - the new name of the authenticated auth-user
   * @param photoURL - the new photo url of the authenticated auth-user
   * @returns -
   */
  // public updateProfile(name: string, photoURL: string): Promise<void> {
  //   return this.afa.currentUser.then((user: User) => {
  //     if (!photoURL) {
  //       return user.updateProfile({displayName: name});
  //     } else {
  //       return user.updateProfile({displayName: name, photoURL});
  //     }
  //   });
  // }

  public parseUserInfo(user: User): UserInfo {
    return {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      providerId: user.providerData.length > 0 ? user.providerData[0]?.providerId || '' : '',
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous
    };
  }

  public getUserPhotoUrl(): Observable<string | null> {
    return this._user$.pipe(
      map((user) => {
        if (!user) {
          return null;
        } else if (user.photoURL) {
          return user.photoURL;
        } else if (user.emailVerified) {
          return this.getPhotoPath(Accounts.CHECK);
        } else if (user.isAnonymous) {
          return this.getPhotoPath(Accounts.OFF);
        } else {
          return this.getPhotoPath(Accounts.NONE);
        }
      })
    );
  }

  public getPhotoPath(image: string): string {
    return `assets/auth/user/${image}.svg`;
  }

  public signInWithPhoneNumber() {
    // todo: 3.1.18
  }

  protected async handleSuccess(userCredential: UserCredential) {
    this.onSuccessEmitter.next(userCredential.user);
    // console.log('handlesuccess ', userCredential);

    // this._user$.next(userCredential.user);
    // if (this.config.enableFirestoreSync) {
    //   try {
    //     await this.fireStoreService.updateUserData(this.parseUserInfo(userCredential.user));
    //   } catch (e) {
    //     console.error(`Error occurred while updating user data with firestore: ${e}`);
    //   }
    // }
    if (this.config.toastMessageOnAuthSuccess) {
      const fallbackMessage = `Hello ${
        userCredential?.user?.displayName ? userCredential?.user?.displayName : ''
      }!`;
      this.showToast(this.messageOnAuthSuccess || fallbackMessage);
    }
  }

  protected handleError(error: any) {
    this.notifyError(error);
    console.error(error);
  }

  // Refresh user info. Can be useful for instance to get latest status regarding email verification.
  public reloadUserInfo() {
    return this.usr ? this.usr.reload() : Promise.reject('no user');
  }

  // Search for an error message.
  // Consumers of this library are given the possibility to provide a
  // function in case they want to instrument message based on error properties.
  public getMessageOnAuthError(error: any) {
    // tslint:disable-next-line:no-bitwise
    return error.toString() || 'Sorry, something went wrong. Please retry later.';
  }

  // Show a toast using current snackbar config. If message is empty, no toast is displayed allowing to opt-out when needed.
  // Default MatSnackBarConfig has no duration, meaning it stays visible forever.
  // If that's the case, an action button is added to allow the end-user to dismiss the toast.
  public showToast(message: string) {
    if (message) {
      this.snackBar.open(message, this.matSnackBarConfig.duration ? undefined : 'OK', {
        ...this.matSnackBarConfig,
        duration: 5000
      });
    }
  }

  public showErrorToast(error: any) {
    if (this.config.toastMessageOnAuthError) {
      this.showToast(this.getMessageOnAuthError(error));
    }
  }

  public notifyError(error: any) {
    this.onErrorEmitter.emit(error);
    this.showErrorToast(error);
  }
}

function getAnonymousUId() {
  let uid = autoId();
  if (sessionStorage) {
    uid = sessionStorage.getItem('anonUId') || uid;
    sessionStorage.setItem('anonUId', uid);
  }
  return uid;
}
function getAnonymousUserName() {
  if (sessionStorage) {
    return sessionStorage.getItem('anonUserName') || '';
  }
  return '';
}

function autoId(): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  while (result.length < 20) {
    const bytes = randomBytes(40);
    bytes.forEach((b) => {
      // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
      // (both inclusive). The value is then evenly mapped to indices of `char`
      // via a modulo operation.
      const maxValue = 62 * 4 - 1;
      if (result.length < 20 && b <= maxValue) {
        result += chars.charAt(b % 62);
      }
    });
  }
  return result;
}

function randomBytes(len: number) {
  const result = [];
  const now = Date.now();
  for (let i = 0; i < len; i++) {
    const num = (Math.random() * 256 + now) % 256;
    result.push(num);
  }
  return result;
}
