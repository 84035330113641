import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { $Device, Device } from '@fhc/shared/interfaces';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { AuthService } from '../auth/services';
import { filter, tap } from 'rxjs/operators';

export abstract class BaseNotificationService {
  // tslint:disable-next-line: variable-name
  private _userId = '';
  private thisDeviceToken = '';
  private storedDeviceToken = '';
  private deviceId = '';
  protected token$ = new BehaviorSubject<string | null>('');
  public readonly hasPermission$ = new BehaviorSubject<boolean>(false);
  public readonly message$ = new BehaviorSubject(null); // todo: filter by user id
  public readonly messages$ = new BehaviorSubject<any[]>([]);
  public readonly messageClick$ = new Subject();
  protected messageQueue: any[] = [];
  // automatically store device token when logged in and token is available

  constructor(
    protected auth: AuthService,
    protected afFunc: Functions,
    protected os = 'web',
    protected service = 'web'
  ) {
    console.log('base notification service');
    this.auth.user$.subscribe((user) => (this.userId = (user && user.uid) || ''));
    this.registerClickHandler();
    combineLatest([this.auth.user$, this.token$])
      .pipe(
        tap(([user, token]) => {
          console.log('userandtoken ', user, token);
          this.thisDeviceToken = token || '';
        }),
        filter(([user, token]) => !!(user && user.uid && token))
      )
      .subscribe(([user, token]) => this.storeDeviceToken(token || ''));
  }

  public set userId(userId: string) {
    console.log('userid', userId);
    this._userId = userId;
    if (userId) {
      this.getStoredDeviceToken();
      this.registerMessageHandler();
    }
  }
  protected abstract registerMessageHandler(): void;
  protected abstract registerClickHandler(): void;
  public abstract requestPermission(vapidKey: string): Promise<void>;

  protected onMessage(message: any) {
    console.log('onMessage', message);
    const data = message.data || {};
    const opts = data?.notification;
    const userId = this._userId || '';
    if (data.warmup) {
      console.log('warmup message received');
      return;
    }
    this.messageQueue.push(message);
    this.messages$.next(this.messageQueue);
    this.message$.next(message);
    // this.notifyDelivery(opts.userId, opts.deviceId, opts.tagId, opts.chatId);
    this.notifyDelivery(data.tagId, data.chatId);
  }

  protected onMessageClick(message: any) {
    this.messageClick$.next(message);
  }

  public dismissMessage(msg: any) {
    const index = this.messageQueue.findIndex((message) => message === msg);
    if (index >= 0) {
      delete this.messageQueue[index];
      this.messages$.next(this.messageQueue);
    }
  }

  public async getUserDevices(): Promise<Device[]> {
    return httpsCallable(
      this.afFunc,
      'userdevices'
    )({ token: this.thisDeviceToken }).then((response) => response.data as Device[]);
  }

  public async addThisDevice() {
    if (!this.thisDeviceToken) {
      return Promise.reject('Device token not available');
    }
    return this.storeDeviceToken(this.thisDeviceToken, true);
  }

  protected async storeDeviceToken(token: string, force = false): Promise<void> {
    console.log('trying to store token...', token);
    if (!this.auth.user || !this.auth.user.uid) {
      // we have the token, but we're not logged in yet
      console.log('no uid to store token');
      return Promise.reject('Not authenticated');
    }
    return new Promise((resolve, reject) => {
      if (this.storedDeviceToken === token && !force) {
        console.log('skip storing token');
        return resolve();
      }
      const body: Partial<$Device> = {
        userId: this._userId,
        token,
        ua: navigator.userAgent,
        os: this.os,
        service: this.service
      };
      console.log(body);
      httpsCallable(
        this.afFunc,
        'userdevice'
      )(body)
        .then((response) => {
          console.log('deviceId', response);
          this.deviceId = response.data as string;
          this.storedDeviceToken = token;
          console.log(response);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  /*Notify backend of notification delivery */
  private notifyDelivery(tagId: string, chatId: string): Promise<any> {
    const data = {
      userId: this._userId,
      deviceId: this.deviceId,
      chatId: chatId,
      tagId: tagId,
      foreground: true
    };
    return httpsCallable(this.afFunc, 'notificationReceived')(data);
  }

  private getStoredDeviceToken() {
    const storedToken = window?.localStorage?.getItem('utoken' + this._userId);
    if (!storedToken) {
      this.storedDeviceToken = '';
      this.deviceId = '';
      return;
    }
    const [token, deviceId] = storedToken.split(' ');
    this.storedDeviceToken = token;
    this.deviceId = deviceId;
  }
}
