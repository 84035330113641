import { Component, OnInit } from '@angular/core';
import { ChatService } from '@fhc/data/chat/chat.service';
import { ActivatedRoute } from '@angular/router';
import { share } from 'rxjs/operators';
import { AuthService } from '@fhc/app/auth/services';

const nullPos: GeolocationPosition = {
  timestamp: 0,
  coords: { latitude: 0, longitude: 0, accuracy: 0, altitude: 0, altitudeAccuracy: 0, heading: 0, speed: 0 }
};

@Component({
  selector: 'page-respond',
  templateUrl: './respond.component.html',
  styleUrls: ['./respond.component.scss']
})
export class RespondComponent implements OnInit {
  private tagId = this.route.snapshot.queryParamMap.get('tagId') || '';
  private chatId = this.route.snapshot.queryParamMap.get('chatId') || '';
  public userId = '';
  public chat$ = this.chatService.chat$;
  public user$ = this.auth.user$.pipe(share());
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private chatService: ChatService) {
  }
  ngOnInit() {
    this.chatService.getChat(this.tagId, this.chatId).subscribe();
    this.auth.user$.subscribe((user) => this.userId = user ? user.uid : this.auth.anonymousUId);
    // window.sessionStorage.removeItem('tag');
  }

  public sendMsg(text: string) {
    this.chatService.sendMsg(text);
  }

}
