import { Component } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

}
