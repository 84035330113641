import { Component, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AuthProvider } from '../../services/auth.provider';
import { AuthAnimations } from '../../animations';
import { LegalityDialogComponent } from '../legality-dialog/legality-dialog.component';
import { LegalityDialogParams, LegalityDialogResult } from '../../interfaces';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export enum Theme {
  DEFAULT = 'default',
  CLASSIC = 'classic',
  STROKED = 'stroked',
  FAB = 'fab',
  MINI_FAB = 'mini-fab',
  RAISED = 'raised',
}

export enum Layout {
  ROW = 'row',
  COLUMN = 'column'
}

@Component({
  selector: 'auth-providers',
  templateUrl: 'auth.providers.component.html',
  styleUrls: ['auth.providers.component.scss'],
  animations: AuthAnimations
})
export class AuthProvidersComponent {

  @Input() theme: Theme = Theme.DEFAULT;
  @Input() layout: string = Layout.COLUMN;
  @Input() providers: AuthProvider[] | AuthProvider = AuthProvider.ALL; //  google, facebook, twitter, github, microsoft, yahoo

  // @Output() onSuccess = this.authProcess.onSuccessEmitter;
  // @Output() onError = this.authProcess.onErrorEmitter;

  @Input() tosUrl: string = '';
  @Input() privacyPolicyUrl: string = '';
  dialogRef: MatDialogRef<LegalityDialogComponent> | null = null;

  themes = Theme;
  authProvider = AuthProvider;

  constructor(public authProcess: AuthService, public dialog: MatDialog) { }

  processLegalSignUP(authProvider: AuthProvider) {
    if (this.tosUrl || this.privacyPolicyUrl) {
      const params: LegalityDialogParams = {
        tosUrl: this.tosUrl,
        privacyPolicyUrl: this.privacyPolicyUrl,
        authProvider
      };

      this.dialogRef = this.dialog.open(LegalityDialogComponent, { data: params });
      this.dialogRef.afterClosed().subscribe((result: LegalityDialogResult) => {
        if (result && result.checked) {
          // this._afterSignUpMiddleware(result.authProvider).then(() => this.signUpFormGroup.reset());
          this.authProcess.signInWith(authProvider);
        }
        this.dialogRef = null;
      });
    } else if(authProvider) {
      // this._afterSignUpMiddleware(authProvider).then(() => this.signUpFormGroup.reset());
      this.authProcess.signInWith(authProvider);
    } else {
      throw new Error('Missing auth provider');
    }
  }

}
