import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// function startServiceWorker() {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js').then(() => {
//       console.log('sw registered');
//     }, (err) => console.log('sw registration failed', err));
//   } else {
//     console.log('sw not supported');
//   }
// }
