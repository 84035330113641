import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RespondComponent } from './respond.component';
import { ChatModule } from '@fhc/features/chat';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    ChatModule
  ],
  declarations: [RespondComponent]
})
export class RespondModule { }
export * from './respond.component';
