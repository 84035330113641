import { Component, Input, ElementRef } from '@angular/core';
import { ChatMessage } from '@fhc/data/chat/chat.service';

@Component({
  selector: 'chat-log',
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.scss']
})
export class ChatLogComponent {
  public visibleMessages: ChatMessage[] = [];
  @Input()
  public set messages(messages: ChatMessage[]) {
    this.visibleMessages = messages;
    setTimeout(() => {
      this.elRef.nativeElement.scrollTop = 999999;
    }, 100);
  }

  @Input()
  public userId = '';
  constructor(private elRef: ElementRef) {}
  getId = (obj: any) => obj?.id || '';
}
