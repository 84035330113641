import { Injectable, OnDestroy } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { Subscription, ReplaySubject, from, Observable, combineLatest } from 'rxjs';
import { switchMap, mergeMap, map, skipWhile } from 'rxjs/operators';
import {
  addDoc,
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  updateDoc
} from '@angular/fire/firestore';
import { Chat, Message } from '@fhc/shared/interfaces';
import { AuthService } from '@fhc/app/auth/services';
import { UserInfo } from '@fhc/app/auth/interfaces/user.interface';

export interface ChatMessage extends Message {
  name: string;
  img: string;
}

export interface ChatWithMsg extends Chat {
  messages: Message[];
}

// TODO: Add Angular decorator.
// TODO: remove stateful functionality and move it to feature
@Injectable({ providedIn: 'root' })
export class ChatService implements OnDestroy {
  private userId = '';
  private isInitiator = false;
  private initiatorUId = '';
  private initiatorName = '';
  private numMessages = 0;
  private _chat$!: Observable<ChatWithMsg>;
  public chat$ = new ReplaySubject<ChatWithMsg>(1);
  public messages$ = new ReplaySubject<Message[]>(1); // this.chat$.pipe(map((chat) => chat && chat.messages || []));
  public message$ = this.messages$.pipe(
    mergeMap((messages) => {
      const result = messages.slice(this.numMessages);
      this.numMessages = messages.length;
      return from(result);
    })
  );
  private tagId = ''; // current tag
  private chatId = ''; // current chat
  private currentChatSub: Subscription | null = null;
  constructor(
    // private afMessaging: AngularFireMessaging,
    private auth: AuthService,
    private afFunc: Functions,
    private afStore: Firestore
  ) {
    this.auth.user$.subscribe((user) => this.onUser(user));
  }

  private get path() {
    return `tags/${this.tagId}/chats/${this.chatId}`;
  }
  private get msgPath() {
    return `tags/${this.tagId}/chats/${this.chatId}/messages`;
  }

  ngOnDestroy() {
    if (this.currentChatSub) {
      this.currentChatSub.unsubscribe();
    }
  }

  public startChat(tagId: string, geoPos: GeolocationPosition): Observable<ChatWithMsg> {
    const coords = geoPos.coords || { latitude: 0, longitude: 0 };
    const pos = {
      coords: {
        latitude: coords.latitude,
        longitude: coords.longitude
      },
      timestamp: geoPos.timestamp
    };
    const msg = {
      tagId,
      geoPos: pos,
      initiatorName: this.initiatorName || ''
    };
    window.sessionStorage.setItem('tagId', tagId);
    this.isInitiator = true;
    return from(httpsCallable(this.afFunc, 'chat')(msg)).pipe(
      switchMap((result: HttpsCallableResult<any>) => {
        console.log(result);
        if (!this.userId) {
          this.initiatorUId = result.data.initiator;
        }
        window.sessionStorage.setItem('chatId', result.data.chatId);
        return this.getChat(tagId, result.data.chatId);
      })
    );
  }

  public getChat(tagId: string, chatId: string): Observable<ChatWithMsg> {
    if (!tagId || !chatId) {
      throw new Error('invalid chat');
    }
    this.chatId = chatId;
    this.tagId = tagId;
    console.log('getting chat ', this.path);

    const chat$ = docData(doc(this.afStore, this.path));
    this.afStore, this.msgPath;
    const msg$ = collectionData(collection(this.afStore, this.msgPath));
    window.sessionStorage.setItem('tagId', tagId);
    window.sessionStorage.setItem('chatId', chatId);
    this._chat$ = combineLatest([chat$, msg$]).pipe(
      skipWhile(([chat, msg]) => !chat || !msg),
      map(([chat, msg]) => {
        if (!chat) {
          throw new Error('');
        }
        chat.messages = msg.sort((a, b) => a.msgDate - b.msgDate);
        this.chat$.next({ ...chat } as ChatWithMsg);
        return chat as ChatWithMsg;
      })
    );
    return this._chat$;
  }

  public sendMsg(text: string, data?: any) {
    return this.sendMsgTo(this.tagId, this.chatId, text, data);
  }

  public sendMsgTo(tagId: string, chatId: string, text: string, data = {}) {
    const user = this.auth.user;
    const msg: ChatMessage = {
      text,
      userId: this.userId || this.auth.anonymousUId || '',
      name: user?.displayName || '',
      img: user?.photoURL || '',
      readBy: [],
      data,
      msgDate: new Date()
    };
    return addDoc(collection(this.afStore, this.msgPath), msg);
    // return this.afFunc.httpsCallable('sendMsg')(msg).toPromise();
  }

  public setUserName(userName: string) {
    this.initiatorName = userName;
    if (this.chatId && this.isInitiator) {
      this.updateChat({ initiatorName: userName });
    }
  }

  public tryResume(tagId: string, chatId?: string) {
    const tagIdStore = window.sessionStorage.getItem('tagId');
    const chatId2 = chatId || window.sessionStorage.getItem('chatId');
    console.log('trying to resume..');
    if (this.currentChatSub) {
      return true; // we already have a subscription
    }
    if (tagIdStore === tagId && chatId2) {
      console.log('resuming...');
      this.currentChatSub = this.getChat(tagId, chatId2).subscribe();
      return true;
    }
    return false;
  }
  public endChat() {
    console.log('ending chat');
    this.isInitiator = false;
    this.chatId = '';
    this.tagId = '';
    this.initiatorUId = '';
    if (this.currentChatSub) {
      this.currentChatSub.unsubscribe();
      this.currentChatSub = null;
    }
    // window.sessionStorage.removeItem('tagId');
    // window.sessionStorage.removeItem('chatId');
  }

  private onUser(user: UserInfo) {
    const prevUId = this.userId;
    this.userId = user ? user.uid : this.auth.anonymousUId;
    this.initiatorName = user ? user.displayName || '' : this.initiatorName || 'Anonymous';

    if (this.chatId) {
      if (!prevUId && user) {
        if (this.isInitiator) {
          this.updateChat({
            userId: this.userId,
            initiatorName: user.displayName || ''
          });
        }
      } else if (prevUId !== this.auth.anonymousUId) {
        this.endChat();
      }
    }
  }

  private async updateChat(data: Partial<Chat>) {
    const path = `tags/${this.tagId}/chats/${this.chatId}`;
    console.log('updating chat ', path, this.chatId);
    const chatRef = doc(this.afStore, path);
    updateDoc(chatRef, data);
  }
}
