import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrScanComponent } from './qr-scan.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [QrScanComponent],
  exports: [QrScanComponent]
})
export class QrScanModule { }
