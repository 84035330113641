import { Injectable } from '@angular/core';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';
import { Functions } from '@angular/fire/functions';
import { BaseNotificationService } from './base-notification.service';
import { AuthService } from '../auth/services';

const NotificationApi = 'Notification' in window;

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseNotificationService {
  // tslint:disable-next-line: variable-name
  public readonly hasSupport = !!NotificationApi;
  private serviceWorkerReg?: ServiceWorkerRegistration;

  constructor(private afMessaging: Messaging, afFunc: Functions, auth: AuthService) {
    super(auth, afFunc);
    console.log('fc msg');
    this.hasPermission$.next(NotificationApi && Notification.permission === 'granted');
    // this.hasPermission$.pipe(filter((has) => has), tap(() => this.storeDeviceToken())).subscribe();
    this.hasPermission$.subscribe((hasPermission) => {
      console.log('permission', hasPermission, this.token$.value);
      if (hasPermission && this.token$.value === '') {
        //this.requestPermission();
      }
    });

    this.registerSW();

    //   this.message$ = new Observable((sub) => onMessage(messaging, (it) => sub.next(it))).pipe(
    //     tap((it) => console.log('FCM', it))
    //   );

    // this.afMessaging;
    // tokenChanges.subscribe((token) => {
    //   console.log('token', token);
    //   this.token$.next(token);
    // });
    // this.afMessaging.requestToken.subscribe((token) => console.log('token2' + token));
    // this.afMessaging.getToken.subscribe((token) => console.log('token3' + token));
  }

  private registerSW() {
    return navigator.serviceWorker
      .register('firebase-messaging-sw.js', { type: 'classic', scope: '__' }) // v9 use type 'module' .. bad browser support for now
      .then(
        (serviceWorkerRegistration) => (this.serviceWorkerReg = serviceWorkerRegistration)
        // getToken(this.afMessaging, {
        //   serviceWorkerRegistration,
        //   vapidKey
        // }).then((token) => this.token$.next(token))
      );
  }

  public requestPermission(vapidKey: string): Promise<void> {
    if (!NotificationApi) {
      console.log('no notification api');
      return Promise.reject(new Error('No Notification support'));
    }
    return getToken(this.afMessaging, {
      serviceWorkerRegistration: this.serviceWorkerReg,
      vapidKey
    }).then((token) => this.token$.next(token));
    // return Notification.requestPermission().then((value) => {
    //   if (value === 'granted') {
    //     this.hasPermission$.next(true);
    //   }
    // });
  }

  protected registerMessageHandler() {
    onMessage(this.afMessaging, (payload) => {
      if (payload.data?.warmup) {
        console.log('warmup message received');
        return;
      }
      console.log('new message received. ', payload);
      this.onMessage(payload);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected registerClickHandler() {}
}
